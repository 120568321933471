<div class="simpleCalculatorResult-container">
  <div class="simpleCalculatorResultTitle">
    <h2>Résultat de la simulation</h2>
    <button (click)="onRollBack()">X</button>
  </div>
  <div class="simpleCalculatorResult">
    <div class="firstResultSection">
      <div class="firstResultLeftSection">
        <div class="tabTitle0">
          <h2>Répartition du <br>chiffre d'affaires</h2>
        </div>
        <div class="grossSalaryContainer">
          <div class="grossSalary">CA : {{ this.resultData?.turnover }}</div>
          <div class="perMonth">&nbsp;/&nbsp;mois</div>
        </div>
      </div>
      <div class="firstResultRightSection">
        <apx-chart class="chartContainer"
                   [chart]="chart"
                   [plotOptions]="chartPlotOptions"
                   [dataLabels]="chartDataLabels"
                   [colors]="chartColors"
                   [legend]="chartLegend"
                   [series]="chartSeries"
                   [labels]="chartLabels"
        ></apx-chart>
        <img class="logoInChartCircle" ngSrc="assets/brand/logoSeulReverseBlue-200.webp" alt="logo portagile" height="199"
             width="200">
      </div>
    </div>
    <div class="secondResultSection">
      <div class="column">
        <div class="tabTitle1">Pour vous</div>
        <div class="money">
          <div class="moneyValue">
            {{ this.resultData?.forYou }}&nbsp;€
          </div>
          <div class="moneyContent">
            Salaire net&nbsp;: <strong>{{ this.resultData?.netSalary }}&nbsp;€</strong><br>
            Frais professionnels&nbsp;: <strong>{{ this.resultData?.professionalExpenses }}&nbsp;€</strong><br>
            Réserve financière&nbsp;: <strong>{{ this.resultData?.financialReserve }}&nbsp;€</strong><br>
            Réserve congés payés&nbsp;: <strong>{{ this.resultData?.paidLeaveReserve }}&nbsp;€</strong>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="tabTitle2">
          Frais de gestion<br>
          PortAgile
        </div>
        <div class="money">
          <div class="moneyValue">
            {{ this.resultData?.portAgileManagementFees }}&nbsp;€
          </div>
<!--          <div class="lines">-->
<!--            <div class="line"></div>-->
<!--            <div class="line"></div>-->
<!--            <div class="line"></div>-->
<!--            <div class="line"></div>-->
<!--            <div class="line"></div>-->
<!--            <div class="line"></div>-->
<!--          </div>-->
        </div>
      </div>
      <div class="column">
        <div class="tabTitle3">Charges</div>
        <div class="money">
          <div class="moneyValue">
            {{ this.resultData?.charges }}&nbsp;€
          </div>
<!--          <div class="lines">-->
<!--            <div class="line"></div>-->
<!--            <div class="line"></div>-->
<!--            <div class="line"></div>-->
<!--            <div class="line"></div>-->
<!--            <div class="line"></div>-->
<!--            <div class="line"></div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</div>

