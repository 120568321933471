import { Component } from '@angular/core';
import {H1TemplateComponent} from "../../components/header/h1-template/h1-template.component";
import {
  SimpleCalculatorFormComponent
} from "../../components/actions-components/simple-calculator-form/simple-calculator-form.component";
import {
  SimpleCalculatorResultComponent
} from "../../components/actions-components/simple-calculator-result/simple-calculator-result.component";
import {NgIf} from "@angular/common";
import {SimpleCalculatorModel} from "../../models/simple-calculator.model";

@Component({
  selector: 'app-votre-remuneration',
  standalone: true,
  imports: [
    H1TemplateComponent,
    SimpleCalculatorFormComponent,
    SimpleCalculatorResultComponent,
    NgIf
  ],
  templateUrl: './votre-remuneration.component.html',
  styleUrl: './votre-remuneration.component.css'
})
export class VotreRemunerationComponent {
  resultData: SimpleCalculatorModel|null = null;
  formSave:any=null;

  onFormSubmit(data: SimpleCalculatorModel) {
    this.resultData = data;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  onFormSave(data:any) {
    this.formSave = data;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  onFormRollBack() {
    this.resultData = null;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
