import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SimpleCalculatorModel} from "../../../models/simple-calculator.model";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-simple-calculator-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
  ],
  templateUrl: './simple-calculator-form.component.html',
  styleUrl: './simple-calculator-form.component.css'
})
export class SimpleCalculatorFormComponent implements OnChanges {

  @Output() formSubmit: EventEmitter<SimpleCalculatorModel> = new EventEmitter<SimpleCalculatorModel>();

  @Output() formSave: EventEmitter<any> = new EventEmitter<any>();

  @Input() formRolBack: any = null;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formRolBack'] && changes['formRolBack'].currentValue) {
      this.simpleCalculatorForm.setValue(this.formRolBack);
      if (this.formRolBack.revenueType === 'mensuel') {
        this.showBillingDaysInput = false;
      }
    }
  }

  simpleCalculatorForm: FormGroup = this.formBuilder.group({
    revenueType: ['journalier', Validators.required],
    revenue: [420, Validators.required],
    billingDays: [19, [Validators.required, Validators.min(1), Validators.max(27)]],
    paidLeave: [true, Validators.required],
    expenses: [200, [Validators.required, Validators.min(0)]]
  });

  showBillingDaysInput = true;

  constructor(private formBuilder: FormBuilder) {
    const revenueTypeControl = this.simpleCalculatorForm.get('revenueType');
    const revenueControl = this.simpleCalculatorForm.get('revenue');

    if (revenueTypeControl && revenueControl) {
      revenueTypeControl.valueChanges.subscribe((newRevenueType) => {
        if (newRevenueType === 'mensuel' && revenueControl.value === 420) {
          revenueControl.setValue(7560);
        } else if (newRevenueType === 'journalier' && revenueControl.value === 7560) {
          revenueControl.setValue(420);
        }
      });
    }
  }

  updateBillingDaysValue(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.simpleCalculatorForm.patchValue({billingDays: value});
  }

  onSubmit() {
    if (this.simpleCalculatorForm.valid) {
      const formValue = this.simpleCalculatorForm.value;
      const simpleForm = new SimpleCalculatorModel(formValue);
      this.formSubmit.emit(simpleForm);
      this.formSave.emit(formValue);
    }
  }
}
